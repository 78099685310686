<template>
  <div id="category-edit">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="AddRecordPrompt" persistent max-width="75%">
        <category-sub-create
          :AddRecordPrompt="AddRecordPrompt"
          :CategoryCode="CategoryId"
          @hideDialog="hideAddRecordPrompt"
          v-if="AddRecordPrompt"
        ></category-sub-create>
      </v-dialog>

      <v-dialog v-model="EditRecordPrompt" persistent max-width="75%">
        <category-sub-edit
          :EditRecordPrompt="EditRecordPrompt"
          :recordData="selectedData"
          @hideDialog="hideEditRecordPrompt"
          v-if="EditRecordPrompt"
        ></category-sub-edit>
      </v-dialog>

      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="backPage"
                color="primary"
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
              >
                <v-icon dark>mdi-arrow-left-circle</v-icon>&nbsp; Back
              </v-btn>
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#EDBE38"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Category Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="CategoryName"
                    :rules="CategoryNameRules"
                    placeholder="Category Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Category Field Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="CategoryFieldName"
                    :rules="CategoryFieldNameRules"
                    placeholder="Category field Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Category Description
                    </h6>
                  </label>
                  <v-text-field
                    v-model="CategoryDescription"
                    :rules="CategoryDescriptionRules"
                    placeholder="Category Description"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Category Status
                    </h6>
                  </label>
                  <v-switch
                    inset
                    v-model="CategoryStatus"
                    :label="`${
                            CategoryStatus
                              ? 'Active'
                              : 'Inactive'
                          }`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-title class="text-h5">
        <h2>Category Sub List</h2>
      </v-card-title>
      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              v-if="tableOptions1.CreateButtonFlag"
              @click.prevent="AddRecordPrompt = true;"
              color="#8950FC"
              elevation="30"
              shaped
              tile
              large
              class="font-size-h6 px-15 py-4 my-3 mr-3 white--text"
            >Create</v-btn>
          </v-col>
        </v-row>
        <v-container style="overflow: hidden">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                  >{{ item.ActiveStatusTxt }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import CategorySubEdit from "@/view/pages/erp/category/category-sub/CategorySubEdit.vue";
import CategorySubCreate from "@/view/pages/erp/category/category-sub/CategorySubCreate.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { CategorySubCreate, CategorySubEdit },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      AddRecordPrompt: false,
      EditRecordPrompt: false,

      CategoryId: "",
      CategoryName: "",
      CategoryFieldName: "",
      CategoryDescription: "",
      CategoryStatus: 1,

      CategoryNameRules: [v => !!v || "Category Name is required"],
      CategoryFieldNameRules: [v => !!v || "Category Field Name is required"],
      CategoryDescriptionRules: [],

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",

      row: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        var CategoryId = this.$route.query.v1;
        CategoryId = CategoryId == (null || undefined) ? 0 : CategoryId;
        console.log({ CategoryId });
        this.CategoryId = CategoryId;

        this.getTableRecords();
        this.getCategoryDetails();
      }
    },
    row: function() {
      console.log("watch row");

      this.CategoryName = this.row.CategoryName;
      this.CategoryFieldName = this.row.CategoryFieldName;
      this.CategoryDescription = this.row.CategoryDescription;
      this.CategoryStatus = this.row.ActiveStatus;
    }
  },
  mounted() {},
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "category_settings",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getCategoryDetails() {
      console.log("getCategoryDetails is called");
      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.row = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/category/show";
      var upload = {
        UserInterface: 1,
        Category: this.CategoryId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          if (flag == 1) {
            thisIns.row = records;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getTableRecords() {
      console.log("getTableRecords is called");
      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/category-sub/lists";
      var upload = {
        UserInterface: 1,
        Category: this.CategoryId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    addRecord() {
      console.log("addRecord called");
      this.AddRecordPrompt = true;
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.AddRecordPrompt = false;
      this.getTableRecords();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one city to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.EditRecordPrompt = false;
      this.getTableRecords();
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Category Sub Name <b> " + tr.CategorySubName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6"
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(data) {
      console.log("deleteData is called");

      var CategoryId = data.CategoryId;
      var CategorySubId = data.CategorySubId;
      console.log(
        "CategoryId=" + CategoryId + "CategorySubId=" + CategorySubId
      );

      if (CategoryId > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/category-sub/destroy";
        var upload = {
          UserInterface: 1,
          Category: CategoryId,
          CategorySub: CategorySubId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Category Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          Category: this.CategoryId,
          CategoryName: this.CategoryName,
          CategoryFieldName: this.CategoryFieldName,
          CategoryDescription: this.CategoryDescription,
          CategoryStatus: this.CategoryStatus
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/category/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.backPage();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#category-edit {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>